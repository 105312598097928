import template from 'lodash/template';
import forEach from 'lodash/forEach';

const PrismicLinkResolver = (_rules: {
  [type: string]: {
    value: string;
    compile?: (data: { uid: string }) => string;
  };
}) => {
  const rules = {};
  forEach(_rules, (rule, key) => {
    rules[key] = {
      ...rule,
      compile: template(rule.value)
    };
  });
  return function (options) {
    if (!options) {
      return '#';
    }
    const data = options._meta || options || {};
    const { url, type, __typename } = data;
    if (url) {
      return url;
    }

    const rule = rules[type] || rules[__typename];

    if (rule) {
      return rule.compile({ uid: '', ...data });
    }
    console.warn(`Link not resolved:`, data);

    return '/';
  };
};

export default PrismicLinkResolver;
