import Button from '@leuven2030/framework/Button/Button';
import UserContext from '@leuven2030/framework/User/UserContext';
import BrandLogo from '@leuven2030/framework/Brand/BrandLogo';
import React, { useContext } from 'react';
import 'twin.macro';
import Link from 'next/link';
import { StyleContentContainer } from '@leuven2030/framework/Style/StyleContent';
const PageLayout = ({ children }) => {
  const { signOut, user } = useContext(UserContext);
  return (
    <div tw="min-h-screen bg-gray-200">
      <div
        tw=" bg-gray-100 py-3 flex items-center justify-between"
        css={StyleContentContainer}
      >
        <Link href="/">
          <div tw="flex items-center justify-start space-x-2 cursor-pointer">
            <BrandLogo tw="h-6 w-auto" />
            <div>
              <h2 tw="text-primary-400 font-bold leading-none">MONITOR</h2>
              <p tw="text-orange-600 text-xs">BETA</p>
            </div>
          </div>
        </Link>
        {user && (
          <div tw="flex items-center space-x-3">
            <p>{user.name || user.email}</p>
            <div
              onClick={() => {
                signOut();
              }}
            >
              <Button variant="contained">Sign out</Button>
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default PageLayout;
