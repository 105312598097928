import MaterialDialog from '@material-ui/core/Dialog';
import tw from 'twin.macro';
import React from 'react';
import useBreakpoint from '@leuven2030/framework/hooks/useBreakpoint';
import ModalDialogContent from '@leuven2030/framework/Modal/ModalDialogContent';
const ModalDialog = ({ children, onClose, open, ...rest }) => {
  const smBreakpoint = useBreakpoint();
  return (
    <MaterialDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      css={tw`py-6 flex flex-col items-center justify-center overflow-hidden`}
      PaperProps={{
        style: smBreakpoint
          ? tw`w-screen max-w-full rounded-none shadow-none max-h-screen`
          : tw`overflow-hidden flex-1`
      }}
    >
      <ModalDialogContent onClose={onClose} {...rest}>
        {children}
      </ModalDialogContent>
    </MaterialDialog>
  );
};
export default ModalDialog;
