import React, { useContext } from 'react';
import Head from 'next/head';
import { PageContext } from '@leuven2030/framework/Page/Page';
import get from 'lodash/get';
const PageHead = () => {
  const {
    prismic: { layout, page }
  } = useContext(PageContext);
  if (!layout) {
    return <></>;
  }
  const title = get(page, 'title') || get(layout, 'website_title');

  return <Head>{title && <title>{title}</title>}</Head>;
};

export default PageHead;
