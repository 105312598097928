import React, { createContext, useContext, useEffect, useState } from 'react';

import UserContext from '@leuven2030/framework/User/UserContext';
export const UserMenuContext = createContext({ items: [] });

const UserMenuProvider = ({ children }) => {
  const { signOut } = useContext(UserContext);
  const [items, setItems] = useState(getItems());

  function getItems() {
    return [];
  }

  useEffect(() => {
    setItems(getItems());
  }, [signOut]);

  return (
    <UserMenuContext.Provider value={{ items }}>
      {children}
    </UserMenuContext.Provider>
  );
};

export default UserMenuProvider;
