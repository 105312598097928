import Loader from '@leuven2030/framework/Loader/Loader';
import PageLayout from '@leuven2030/monitor/Page/PageLayout';
import React from 'react';
const PageFallback = () => {
  return (
    <PageLayout>
      <div tw="flex justify-center items-start w-full">
        <Loader />
      </div>
    </PageLayout>
  );
};

export default PageFallback;
