import Button from '@leuven2030/framework/Button/Button';
import CloseIcon from '@material-ui/icons/Close';
import ButtonBottom from '@leuven2030/framework/Button/ButtonBottom';
import React from 'react';
import useBodyLock from '@leuven2030/framework/hooks/useBodyLock';
import MaterialDialogContent from '@material-ui/core/DialogContent';
import 'twin.macro';

const ModalDialogContent = ({
  onClose,
  title,
  titleProps,
  children,
  ...rest
}) => {
  useBodyLock();
  return (
    <div {...rest} tw="max-h-screen flex flex-col ">
      <div tw="items-start w-full flex justify-between">
        <div {...titleProps}>{title}</div>
        <Button
          tw="bg-gray-100 z-20 hover:(bg-gray-200) rounded-none"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </div>
      <MaterialDialogContent tw="flex-1 p-0! m-0! h-screen sm:(m-12 h-auto) overflow-x-hidden overflow-auto">
        {children}
      </MaterialDialogContent>
    </div>
  );
};

export default ModalDialogContent;
