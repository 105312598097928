import PageFallback from '@leuven2030/monitor/Page/PageFallback';
import React from 'react';
import { useRouter } from 'next/router';
import PageBase from '@leuven2030/framework/Page/Page';
const Page = (Component) => {
  const PageWrapper = () => {
    const router = useRouter();
    if (router.isFallback) {
      return <PageFallback />;
    }
    return <Component />;
  };
  return PageBase(PageWrapper);
};

export default Page;
